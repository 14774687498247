header {
  height: auto !important;
  padding: 0 !important;
  background-color: transparent !important;
}
.card-dark {
  border: 1px solid rgb(90 53 222 / 39%);
  background-color: #000;
}
.card-white {
  border: 1px solid rgb(90 53 222 / 39%);
  background-color: #fff;
}
.card-light {
  background: linear-gradient(#6503ab08, rgb(2 2 65 / 0%));
  border: 1px solid rgb(233 227 255 / 17%);
}
.text-left {
  text-align: left;
}
.text-gr1 {
  background: -webkit-linear-gradient(45deg, #ff9800, #8521f3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.react-toast {
  /* padding: 8px 45px 8px 15px; */
  box-shadow: 0 7px 14px 0 rgb(52 58 64 / 13%);
  border: 1px solid rgba(0, 0, 0, 0.061);
  font-size: 0.9rem;
}
.bg-dark .text-muted {
  color: #dedede !important;
}
.input-error {
  border: 1px solid #f92631a1;
}
.gopdf-document {
  border: 1px solid #3333332e;
  border-radius: 5px;
}
.dropdown-menu.gopdf {
  width: 475px;
}

.custom-row {
  margin: 0 75px;
}
.gopdf-document-save input,
.html-editor input {
  display: inline-block;
  width: 95%;
  font-weight: 600;
  font-family: "Imprima", sans-serif;
  border: 1px solid transparent;
  font-size: 1.25rem;
  background-color: transparent;
  padding: 2px 10px;
  margin-left: 35px;
}
.html-editor input {
  margin-left: 0;
}
.gopdf-document-save input:focus,
.gopdf-document-save input:hover {
  background-color: transparent;
  border: 1px solid #d5d5d5;
}
.document-dashboard {
  border-radius: 5px;
  justify-content: center;
  font-size: 3.5rem;
  opacity: 0.98;
}
.edit-document {
  width: 286px;
}
.gopdf-document-save .document-name-icon {
  position: absolute;
  font-size: 35px;
  margin-top: -7px;
}
.document-dashboard svg {
  cursor: pointer;
}
.document-dashboard:hover {
  /* background-color: #ddf3ff; */
  opacity: 0.9;
}
.document-mic {
  position: absolute;
  bottom: 20px;
  left: 10px;
}
.document-mic .mic-btn svg {
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0.75;
}
.document-dashboard .card-header,
.card-title {
  margin-top: 0;
  /* height: 50px; */
  overflow: hidden;
}
.document-box {
  width: 125px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.document-dashboard .dropdown {
  position: absolute;
  right: 15px;
  top: -30px;
}
.document-dashboard .dropdown-menu.show {
  display: block;
  padding: 5px;
  min-width: 25px;
}
.document-dashboard .document {
  width: 70px;
  cursor: pointer;
  opacity: 0.75;
}
.document-dashboard .document:hover {
  opacity: 0.5;
}
.document-dashboard .dropdown .dropdown-menu .dropdown-item {
  padding: 5px 10px;
}
.document-dashboard .new-document {
  font-size: 2rem;
  background: #fff;
  padding: 15px 20px 10px 20px;
  border: 1px dashed #5e5c5c9c;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  margin: 10px;
  text-align: center;
}
.document-dashboard .new-document:nth-child(1) {
  border: 1px dashed #3c38ff;
  margin-left: 0;
}
.document-dashboard .new-document:nth-child(1) h5 {
  color: #3c38ff !important;
}
.document-dashboard .new-document h5 {
  font-size: 1rem;
  padding: 0;
  margin: 0;
}
.document-dashboard .card-title {
  font-weight: 600;
}
.document-dashboard .new-document:hover {
  background-color: #ddf3ff;
  opacity: 0.95;
}
.dashboard-template .card-img-top-overlay.template {
  max-height: 275px;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.dashboard-template .card-img-top-overlay.template:hover {
  box-shadow: 0 0 0.25rem rgb(255 209 72 / 62%);
  transform: scale(1.02);
}
.carousel-template .card-img-top-overlay.template {
  max-height: 275px;
}
body .rsw-ce {
  overflow: auto;
}
body .rsw-ce.rsw-html {
  font-size: 0.85rem;
  background-color: #333;
  color: #fff;
}
body .rsw-editor {
  background-color: #fff;
  border-radius: 3px;
  min-height: 650px;
  border: 1px solid #dee2e6;
}
body .rsw-toolbar {
  display: none;
}
body .email-editor .rsw-toolbar {
  display: block;
  background-color: transparent;
  border: 1px solid #ddd;
  padding: 0 10px;
  border-radius: 3px;
  margin-bottom: -2px;
}
body .email-editor .rsw-toolbar .rsw-dd {
  font-size: 0.9375rem;
  line-height: 1.5;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 5px;
  margin-right: 10px;
}
body .email-editor .rsw-editor {
  height: 380px;
  min-height: 380px;
  overflow: auto;
}
body .rsw-btn {
  font-size: 1.15em;
  width: 2rem;
}
body .rsw-btn:hover {
  background-color: #f0f5ff;
}
body .rsw-editor .rsw-dd {
  display: none;
}
body .offcanvas.email-editor {
  width: 450px;
}
.hidden,
.data-type-label.MuiBox-root {
  display: none;
}
.cursor-pointer {
  cursor: pointer;
}
.MuiPaper-root.MuiPaper-elevation {
  padding: 10px 15px;
}
.highlight-sm::before {
  right: 0rem;
  bottom: 0.05rem;
}
.playground {
  min-height: 90vh;
}
.payment {
  min-height: 520px;
}
pre code.hljs {
  height: 375px;
}
.float-right {
  float: right;
}
/* react-select */
.multi-select {
  font-family: "Imprima", sans-serif;
}
.multi-select .css-13cymwt-control,
.multi-select .css-t3ipsp-control,
.gopdf-multi-select .gopf-select__control {
  padding: 0.15rem 0.5rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: "Imprima", sans-serif;
}
.gopdf-multi-select .gopf-select__placeholder {
  color: #575757;
}
.gopdf-multi-select .gopf-select__placeholder,
.gopf-select__menu .gopf-select__option {
  font-size: 0.9rem;
}
.error .css-13cymwt-control {
  border: 1px solid #f0323c;
}
/* code editor */
body .cm-gutters {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .cm-focused {
  outline: 1px solid transparent !important;
}
body .playground .cm-editor {
  height: 300px;
  min-height: 300px;
}
body .rsw-editor,
body .cm-editor,
body .code-editor {
  height: 93vh;
  min-height: 93vh;
  border-radius: 3px;
  overflow: auto;
}
.body .editor-canvas .cm-editor {
  height: 50vh;
  min-height: 50vh;
}
.html-editor .footer-fixed {
  position: fixed;
  width: 100%;
  bottom: 0px;
  padding-top: 10px;
  padding-bottom: 3px;
}
.html-editor .resize-panel-group {
  height: 92.5vh !important;
}
.html-editor input:focus {
  background-color: #282822;
  border-color: transparent;
}
.html-editor .navbar-nav {
  height: 42px;
}
.html-editor .resize-panel-group {
  margin-top: 0;
}
.html-editor .navbar-nav .dropdown .dropdown-menu {
  background: #fff;
  z-index: 99;
  position: relative !important;
  inset: revert-layer !important;
  margin: auto !important;
  transform: revert-layer !important;
  margin-top: 45px !important;
}
.html-editor .nav-dark {
  padding-top: 12px;
  padding-bottom: 5px;
}
.html-editor .nav-dark .nav-item.dropdown {
  height: 45px;
}
.html-editor .code-editor .ext-link {
  display: none;
}
.html-editor .code-editor:hover .ext-link {
  display: block;
  position: absolute;
  font-size: 1.2rem;
  right: 0;
  background: linear-gradient(#272822 0, #272822 100%) no-repeat left center;
  padding: 3px 8px 8px 10px;
  box-shadow: 3px 3px 3px 3px #92929269;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.html-editor .code-editor .ext-link a {
  color: #fff;
}
.html-editor .code-editor .ext-link:hover a {
  color: #8f8f8f;
}
.account .invoice-btn {
  margin-right: 05px;
}
.support .flex {
  display: flex;
}
.support .flex svg {
  margin-top: 2px;
}
.support-search pre {
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 0.9rem;
}
.navbar-nav .btn-cta {
  font-size: 0.9rem;
}
/*react-hot-toast*/
.react-toast > div:first-child {
  min-width: 30px;
  min-height: 30px;
  position: absolute;
  top: -15px;
  left: -10px;
}
.react-toast > div:first-child div {
  width: 18px;
  height: 18px;
}
.home-highlight {
  border-radius: 5px !important;
}
.offcanvas-backdrop.fade.show {
  pointer-events: none;
}
.splitter {
  width: 100%;
  height: 100%;
  background: #ccc;
}
.product-reviews .review {
  border: 1px solid #d9d9d9b5;
  border-radius: 5px;
}
/*megamenu*/
.megamenu-title:after {
  content: "";
  border: solid #333;
  border-width: 2px 0px 0px 2px;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-left: 20px;
  margin-top: 6px;
  position: absolute;
}
.dropdown-menu-xl .p {
  line-height: 20px;
}
.model-contel2 {
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 6px 2px #3333335c;
  border-radius: 0.25rem;
  position: relative;
}
.screenshot-demo {
  max-height: 86vh;
  overflow: auto;
}
.ocr-page {
  max-height: 92vh;
  min-height: 92vh;
  overflow: auto;
}
.select-dropzone-file {
  text-align: center;
  border: 1px dashed #a4a4a4;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  margin-top: 20vh;
  background-color: #fff;
}
.model-contel2 .new-tab-btn {
  display: none;
  position: absolute;
  padding: 3px 10px;
  right: 10px;
  top: 10px;
  font-size: 13px;
}
.model-contel2:hover .new-tab-btn {
  display: block;
  z-index: 9;
  box-shadow: 5px 9px 9px 2px #3333336b;
}
.screenshot-modal .modal-content {
  width: 450px;
}
body .screenshot-modal .modal-content .cm-editor {
  height: 175px;
  min-height: 175px;
}
.ocr-page .custom-row {
  margin: 0 50px;
  position: relative;
}
.ocr-demo .image-crop-box {
  overflow: auto;
  max-height: 84vh;
  border-radius: 3px;
}
.pdf-to-images .card {
  transition: transform 0.3s ease;
}
.pdf-to-images .card:hover {
  border-color: #ffc107 !important;
  cursor: pointer;
  box-shadow: 0 0 0.25rem rgb(255 209 72 / 62%);
  transform: scale(1.02);
}
.code-editor .doc-id {
  display: none;
}
.code-editor:hover .doc-id {
  display: block;
  position: absolute;
  z-index: 9;
  top: -2px;
  right: 0;
  background: #8bc34a;
  padding: 5px 7px;
  font-size: 14px;
  border-bottom-left-radius: 3px;
}
/*chat-with import-start */
.chat-box {
  height: 92vh;
  position: relative;
  padding: 0 15px;
}
.chat-box .input-group .input {
  line-height: 0;
}
.chat-box .input-group {
  position: absolute;
  bottom: 15px;
  width: 95%;
  background-color: #fff;
}
.chat-box .image-upload {
  position: absolute;
  bottom: 80px;
  right: 20px;
}
.chat-box .image-upload img {
  border: 1px solid #7c7a7a;
}
.chat-box .image-upload .upload-btn {
  position: absolute;
  width: 100%;
  bottom: 40%;
  opacity: 0;
}
.chat-box .image-upload:hover .upload-btn {
  opacity: 1;
}
.chat-pdf .files-section {
  overflow: auto;
  height: 90vh;
  margin-right: 6px;
  position: relative;
  z-index: 99;
  overflow-x: hidden;
  overflow-y: auto;
}
.pdf-toolbar,
.ocr-demo {
  position: relative;
  z-index: 0;
}
.pdf-toolbar .capture,
.ocr-page .capture {
  position: absolute;
  right: 60px;
  z-index: 9;
  cursor: pointer;
  top: 6px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 1.5;
  border-radius: 5px;
}
.ocr-page .capture {
  top: 0;
  right: 0;
  background: #575757;
  color: #fff;
}
.pdf-toolbar .capture:hover,
.ocr-page .capture:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.pdf-toolbar .overlay,
.ocr-page .overlay {
  background-color: transparent;
}
.pdf-toolbar .overlay::before,
.ocr-page .overlay::before {
  background-color: transparent;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px dashed #575757;
  border-radius: 3px;
  transform: scale(1.03);
  box-shadow: -1px 4px 17px 8px #57575785;
}
.ocr-page .btn-image-crop {
  font-size: 1.5rem;
  padding: 5px 8px;
  position: absolute;
  top: 10px;
  right: 12px;
  z-index: 1;
  line-height: 0;
}
.pdf-toolbar .image-block img {
  margin-top: 50px;
}
.pdf-toolbar .image-block .url-block {
  position: absolute;
  width: 100%;
  height: 45px;
  padding-top: 8px;
}
.pdf-toolbar .image-block .url {
  display: inline-block;
  padding: 0px 0px 5px;
  background: #ececf6;
  width: 96%;
  border-radius: 25px;
  position: absolute;
  margin-left: 10px;
}
.pdf-toolbar .image-block .capture {
  right: 18px;
  top: 5px;
  font-size: 20px;
}
.pdf-toolbar .image-block .capture:hover {
  background-color: transparent;
}
.overlay.highlighting {
  border-color: transparent !important;
}
.chat-pdf .dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin: 20px 12px;
  cursor: pointer;
}
.chat-pdf .files-section ul {
  margin: 0;
  padding: 10px;
  list-style-type: none;
}
.chat-pdf .files-section ul li {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
  border: 1px solid #d7d7d7;
  cursor: pointer;
}
.chat-pdf .files-section ul li.selected {
  border: 1px solid #cddc39;
  background-color: #cddc39 !important;
}
.files-section .file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chat-pdf .delete,
.url-close-btn {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: -10px;
  background-color: #f0323c;
  font-size: 1rem;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  text-align: center;
  z-index: 9;
}
.ocr-close-btn {
  cursor: pointer;
  position: absolute;
  top: -25px;
  background-color: #f0323c;
  font-size: 1rem;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  text-align: center;
  z-index: 9;
  left: 10px;
}
.pdf-error {
  height: 500px;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
}
.chat-box .questions {
  position: absolute;
  bottom: 75px;
  border: 1px solid #d6d3d3;
  border-radius: 5px;
  padding: 10px 25px;
  width: 95%;
  background-color: #fff;
  overflow-y: auto;
}
.chat-box .questions ul {
  list-style-type: none;
  padding: 0;
  font-size: 0.95rem;
  font-weight: 500;
}
.chat-box .questions ul li:hover {
  text-decoration: underline;
  cursor: pointer;
}
/*chat-list*/
.chat-list {
  max-height: 100%;
  overflow-y: scroll;
  padding: 15px;
}
.chat-list .applyr-input {
  padding: 0.5rem 1em;
  background-color: transparent;
  border-top: 1px solid #dfdfdf;
  transition: background-color 0.2s ease-in-out, box-shadow 0.5s ease-in-out;
}
.chat-list .applyr-input input {
  font-size: 1rem;
  border: none;
  background-color: transparent;
  opacity: 0.4;
  line-height: 0;
  transition: opacity 0.1s ease-in-out;
}
.chat-list .applyr-input input:focus {
  opacity: 1;
  background-color: transparent;
}

.chat-list .btn-secondary {
  border: none;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  background-color: #3f3e3ed4;
  margin-left: 0px !important;
  border-radius: 4px !important;
  transition: background-color 0.1s ease-in-out, color 0.1 ease-in-out;
}
.chat-list .btn-secondary:hover {
  color: #5c5c5c;
  cursor: pointer;
}

.chat-list .applyr-input:hover {
  box-shadow: 0 -5px 14px 0 rgba(0, 0, 0, 0.05);
}

.chat-list .modal-footer {
  background-color: #f7f7f7;
  border: none;
  color: #919699;
  font-weight: 600;
  font-size: 0.7em;
  letter-spacing: 0.25px;
  border-radius: 0 0 4px 4px;
}
.chat-list .modal-footer .applyr-link {
  opacity: 0.4;
  color: #3f3e3e;
  display: flex;
  transition: opacity 0.3s ease-in-out;
}
.chat-list .modal-footer .applyr-link span {
  line-height: 180%;
  margin-right: 0.5em;
}
.chat-list .modal-footer .applyr-link:hover {
  opacity: 1;
  text-decoration: none;
}

.chat-list .chat-bubble {
  margin-bottom: 0.75em;
  float: left;
  clear: both;
}

.chat-list .chat-bot {
  max-width: 90%;
}

.chat-list .chat-user {
  text-align: left;
  float: right;
  max-width: 90%;
  margin-bottom: 20px;
}

.chat-list .chat-text {
  background-color: #f7f7f7;
  padding: 0.45rem 0.9em;
  border-radius: 6px 6px 6px 0;
  margin-bottom: 0.15em;
  font-size: 0.95rem;
}
.chat-list .chat-user .chat-text {
  background-color: #e3eaff99;
  font-family: "Imprima", sans-serif;
  color: rgb(73, 80, 87);
}

.chat-list .chat-text ol,
.chat-list .chat-text ul {
  padding: 5px 20px;
  margin: 0;
}

.chat-list .chat-text li {
  font-family: "Imprima", sans-serif;
  font-size: 0.95rem;
  letter-spacing: 0.02rem;
  color: rgb(73, 80, 87);
  line-height: 1.4;
}

.chat-list .typing_text {
  font-size: 0.95rem;
  font-family: "Imprima", sans-serif;
  font-feature-settings: "liga", "kern";
  line-height: 1.4;
  letter-spacing: 0.02rem;
  color: rgb(73, 80, 87);
  margin: 5px 5px 8px 5px;
}

.chat-list .chat-user .chat-text img {
  width: 150px;
}

.chat-list .chat-time {
  font-size: 0.7em;
  color: #919699;
}

.chat-list .chat-user .chat-time {
  float: right;
}

.chat-list .modal-body {
  height: 500px;
  overflow: auto;
}
.chat-list .modal-body pre code {
  font-size: 14px;
}
.chat-list .modal-header .ai-icon {
  font-size: 20px;
  cursor: pointer;
  color: #fff;
  margin-left: 15px;
}
.chat-list .loading .new-thread {
  animation: threadloading 1s linear infinite;
}
.chat-list .loading .modal-body {
  opacity: 0.25;
}
.chat-list .assistance-icon {
  display: inline-block;
  position: fixed;
  right: 15px;
  z-index: 99;
  bottom: 15px;
}
.chat-list .assistance-icon svg {
  cursor: pointer;
  box-shadow: 1px 5px 10px 5px #c8c3c3;
  border-radius: 50%;
  line-height: 0;
  background-color: #fff;
  color: #575757;
  transform: rotate(-25deg);
  animation: icon 5s linear;
  font-size: 2.9rem;
}
.chat-list .assistance-icon svg.active {
  font-size: 3rem;
  animation: icon 5s infinite;
  color: #673ab7;
}
@keyframes icon {
  50% {
    transform: rotate(90deg);
  }
}
@keyframes threadloading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.chat-list .chat-bubble.hidden,
.chat-list .Typewriter__cursor {
  display: none;
  opacity: 0;
}
.chat-list p {
  font-size: 0.95rem;
  font-family: "Imprima", sans-serif;
  font-feature-settings: "liga", "kern";
  line-height: 1.4;
  letter-spacing: 0.02rem;
  color: rgb(73, 80, 87);
  margin: 5px 5px 8px 5px;
}
.chat-list th,
.chat-list td {
  font-family: "Imprima", sans-serif;
}
.chat-list .btn-secondary.disabled,
.chat-list .btn-secondary:disabled {
  background-color: #c0c0c0;
  border-color: #ccc;
}
.chat-list .loader-container {
  text-align: center;
  display: block;
  position: relative;
  margin-bottom: 0.5em;
  float: left;
  clear: both;
  margin-left: 50px;
  margin-top: 15px;
  margin-bottom: 100px;
}
.chat-list .loader {
  width: 08px;
  height: 08px;
  border-radius: 50%;
  display: block;
  margin: 10px auto;
  position: relative;
  color: #3f3e3e;
  box-sizing: border-box;
  animation: animloader 1s linear infinite alternate;
}
.chat-list pre {
  background-color: #333333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
}
.ai-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #0000009c;
  left: 0;
  right: 0;
  top: 0;
  overflow: hidden;
  z-index: 99;
}
.ai-open {
  overflow: hidden;
}
/* margin and padding*/
.chat-list .mb-200 {
  margin-bottom: 200px;
}
.chat-list .mb-150 {
  margin-bottom: 150px;
}
.chat-list .mw {
  width: 90%;
}
.bg-upload {
  background: rgba(60, 56, 255, 0.05);
  border: 1px dashed rgba(60, 56, 255, 0.12);
}
.text-black {
  color: #000;
}
/* table-light */
.table-editor td {
  font-family: "Imprima", sans-serif;
  padding: 0.25rem 0rem 0.25rem 0.85rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border-radius: 3px;
}
.table-editor .form-control-sm {
  padding: 0.15rem 0.75rem;
  min-height: 30px !important;
  margin-left: -12px;
  font-size: 0.92rem;
}
@keyframes animloader {
  0% {
    box-shadow: -38px -6px, -14px 6px, 14px -6px;
  }
  33% {
    box-shadow: -38px 6px, -14px -6px, 14px 6px;
  }
  66% {
    box-shadow: -38px -6px, -14px 6px, 14px -6px;
  }
  100% {
    box-shadow: -38px 6px, -14px -6px, 14px 6px;
  }
}
/* pdf-override */
.rpv-default-layout__sidebar,
.rpv-toolbar__left .rpv-toolbar__item:nth-child(1),
.rpv-core__display--block-medium,
.rpv-core__display--hidden-medium {
  display: none !important;
}
.rpv-default-layout__toolbar {
  background-color: transparent !important;
}
.rpv-default-layout__container {
  border: none !important;
}
.rpv-core__textbox {
  height: 1.5rem !important;
  width: 35px !important;
  background-color: transparent !important;
}
/*chat-url*/
.chat-url .image-block {
  position: absolute;
  z-index: 9;
  width: 100%;
}
.chat-url .rpv-default-layout__toolbar {
  display: none;
}
.chat-url .rpv-core__page-layer::after {
  box-shadow: none;
}
.url-overflow {
  max-width: 75%;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.message-input .mic-btn {
  position: absolute;
  left: 50px;
  z-index: 9;
}
.message-input .input {
  padding-left: 45px;
}
.listening {
  animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
/* chat-with import-end */
@media only screen and (max-width: 991px) {
  .resize-panel-group {
    display: block !important;
    margin-top: 75px;
    padding: 0px;
    height: auto !important;
  }
  .pdf-toolbar {
    max-height: 485px;
    border-bottom: 1px solid #575757;
  }
  .chat-pdf .files-section {
    height: auto;
    max-height: 325px;
  }
  .chat-pdf .files-section ul {
    overflow: auto;
    max-height: 185px;
  }
  .chat-bot .questions {
    max-height: 375px;
  }
  .chat-list {
    padding: 0;
  }
  .pdf-toolbar .image-block .url {
    width: 95%;
  }
  .topnav-menu .nav-item.no-user {
    min-width: 205px;
    padding-top: 0;
    padding-bottom: 10px;
  }
  .topnav-menu .nav-item.no-user .ul {
    min-width: 150px;
  }
  .topnav-menu .nav-item.no-user .dropdown-menu {
    padding: 10px;
    display: none;
  }
  .topnav-menu .nav-item.no-user .dropdown-menu.show {
    display: block;
  }
  .topnav-menu .nav-item.no-user .dropdown-toggle {
    min-width: 150px;
  }
  .topnav-menu .nav-item.no-user .ul .nav-item a {
    padding: 2px;
    display: list-item;
  }
  .topnav-menu .navbar-nav .dropdown-menu {
    background-color: #f3f4f7;
    display: none;
    flex-wrap: wrap;
  }
  .topnav-menu .navbar-nav .dropdown-menu.show {
    display: flex;
    max-width: 100%;
    overflow: hidden;
    padding: 10px;
    margin-bottom: 10px;
  }

  .navbar-nav .btn-cta {
    margin: 10px 0;
  }
  .html-editor,
  .gopdf-document-save,
  .custom-row {
    margin-left: -12px;
    margin-right: -12px;
  }
  .card-body.template {
    padding: 0.25rem 0.75rem;
  }
  body .rsw-editor,
  body .cm-editor,
  body .code-editor {
    height: 44vh;
    min-height: 44vh;
    border-radius: 3px;
  }
  .playground h2 {
    margin-top: 50px;
  }
  .html-editor .navbar-nav {
    height: 42px;
    float: right;
    margin-right: 10px;
  }
  .html-editor .nav-dark {
    padding: 0;
  }
  .html-editor .navbar-nav .dropdown .dropdown-menu {
    width: 100%;
  }
  .carousel-template .card-img-top-overlay.template {
    max-height: 185px;
    overflow: hidden;
  }
  img.w-100 {
    width: 50% !important;
  }
  .chat-box {
    height: 60vh;
    position: relative;
    padding: 0 15px;
    border-top: 1px solid #e1d9d9;
  }
  .mt-5,
  .mt-6 {
    margin-top: 1.5rem !important;
  }
  .pt-5 {
    padding-top: 1.5rem !important;
  }
  .mt-7,
  .mt-8 {
    margin-top: 3rem !important;
  }
  .mb-6,
  .mb-5 {
    margin-bottom: 2rem !important;
  }
  .py-3 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  /* font-sizes */
  .fs-40 {
    font-size: 32px !important;
  }
  .mobile-hide {
    display: none;
  }
  .navbar.topnav-menu {
    position: fixed;
    padding: 2px 20px 2px;
  }
  .dashboard,
  .account,
  .templates {
    padding-top: 75px !important;
  }
  .mobile-mr-fix {
    margin-top: 60px !important;
  }
  .html-editor,
  .gopdf-document-save {
    padding: 10px 10px;
    padding-top: 50px;
  }
  .home .hero-container {
    margin-top: 50px !important;
  }
  .account .invoice-btn {
    margin: 0 10px 10px;
  }
  .aos-animate {
    margin-bottom: 50px;
  }
  .screenshot-modal .modal-dialog {
    max-width: 96vw !important;
    margin-top: 50px;
  }
  .screenshot-modal .modal-content .modal-body {
    width: 96vw !important;
  }
  .screenshot-modal .modal-dialog-centered {
    display: block;
  }
  .screenshot-modal .model-contel2 {
    width: 96vw !important;
    margin: auto;
    margin-left: 0 !important;
    margin-top: 15px;
    max-height: 400px;
    overflow: auto;
    text-align: center;
  }
  .screenshot-modal img.w-100 {
    width: 100% !important;
  }
  .screenshot-modal .modal-content {
    width: auto;
  }
  .nav-item.bg-soft-warning {
    margin-left: 0 !important;
  }
  .navbar-expand-lg .nav-item.dropdown {
    overflow: hidden;
  }
  div[class*="styles_ResizeHandleInner"] {
    display: none;
  }
  .ocr-page {
    max-height: 95vh;
  }
  .ocr-page .ocr-demo {
    height: auto !important;
    max-height: 55vh !important;
    overflow: auto;
  }
  .mobile-top-space {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 460px) {
  .h4 {
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }
  .pt-3 {
    padding-top: 0.75rem !important;
  }
  .dropdown-menu-xl .p {
    display: none;
  }
  .navbar-nav .dropdown-menu .nav .nav-item .nav-link {
    padding: 0.35rem 0rem;
  }
  .nav-item.bg-soft-warning {
    margin-left: -20px !important;
    padding-bottom: 10px;
    padding-left: 15px;
    width: 100vw;
  }
  .chat-box .questions {
    max-height: 300px;
    overflow: auto;
    font-size: 0.85rem;
    bottom: 55px;
    width: 100%;
    left: 0;
  }
  .chat-box .input-group {
    bottom: 0;
    width: 96%;
    margin-left: -10px;
  }
  .ocr-page {
    max-height: max-content;
  }
  .ocr-page .custom-row {
    margin: 0;
  }
  .automation-page .custom-row .card-body {
    padding: 0;
  }
  .dropdown-menu-xl svg {
    display: none;
  }
  .dropdown-menu-xl .nav .nav-item a {
    margin-left: 0 !important;
  }
}

/* modal shake fix */
body.modal-open {
  overflow: inherit !important;
}
/*css loader */
.css-loader {
  width: 50px;
  padding: 2px;
  margin: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #1133f1;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 0.75s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

/*unplash*/

.unsplash-search {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;

  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  padding: 0;
}
@media (max-width: 1200px) {
  .unsplash-search {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;

    -webkit-column-gap: 10px;
    -moz-column-gap: 10px;
    column-gap: 10px;
  }
  .gopdf-document-save input,
  .html-editor input {
    margin-bottom: 10px;
  }
}
@media (max-width: 800px) {
  .unsplash-search {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 10px;
    -moz-column-gap: 10px;
    column-gap: 10px;
  }
}
@media (max-width: 600px) {
  .unsplash-search {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}
.unsplash-search img,
.unsplash-search video {
  width: 100%;
  height: auto;
  margin: 2% auto;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  border-radius: 3px;
  max-height: 100%;
  overflow: auto;
  cursor: grab;
}
.unsplash-search img:hover,
.unsplash-search video:hover {
  transform: scale(1.025);
}
.unsplash-loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: linear-gradient(0deg, rgba(90, 90, 91, 0.2) 33%, #2b2a2a 100%);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.unsplash-loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #fff;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*Toggle slider*/
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 15px;
  margin-top: 10px;
}

.switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Default background color */
  transition: 0.4s;
  border-radius: 34px;
}

.slider::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: -3px;
  background-color: #495057;
  transition: 0.4s;
  border-radius: 50%;
}

.switch-input:checked + .slider {
  background-color: #4caf50; /* Background color when checked */
}

.switch-input:checked + .slider::before {
  transform: translateX(36px);
}

.border-danger .slider {
  border: 2px solid red !important;
}

.border-success .slider {
  border: 2px solid green !important;
}

.border-warning .slider {
  border: 2px solid orange !important;
}
